// brand.reducer.js
import * as ActionType from "./brand.type";

const initialState = {
  brands: [],
};

export const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case ActionType.CREATE_BRAND:
      return {
        ...state,
        brands: [action.payload, ...state.brands],
      };
    case ActionType.UPDATE_BRAND:
      return {
        ...state,
        brands: state.brands.map((brand) =>
          brand._id === action.payload._id ? action.payload : brand
        ),
      };
    case ActionType.DELETE_BRAND:
      return {
        ...state,
        brands: state.brands.filter((brand) => brand._id !== action.payload),
      };
    default:
      return state;
  }
};
