import React, { useState } from "react";
import { connect } from "react-redux";
import { signupAdmin } from "../store/admin/admin.action";
import Button from "../extra/Button";
import Input from "../extra/Input";

const Registration = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState({
    email: "",
    password: "",
    newPassword: "",
    code: "",
  });

  const isEmail = (value) => {
    const validEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
    return validEmail;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password || !code || !newPassword || !isEmail(email) || newPassword !== password) {
      let error = {};
      if (!email) error.email = "Email is required!";
      if (!password) error.password = "Password is required!";
      if (!newPassword) error.newPassword = "New password is required!";
      if (newPassword !== password) error.newPassword = "Passwords do not match!";
      if (!code) error.code = "Purchase code is required!";
      setError(error);
      return;
    }

    const registrationData = {
      email,
      newPassword,
      password,
      code,
    };

    props.signupAdmin(registrationData);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <div className="mainLoginPage">
      <div className="loginDiv">
        <div className="row">
          <div
            className="col-xl-6 d-xxl-block d-xl-block d-none boxCenter"
            style={{ background: "#b93160" }}
          >
            <div className="p-5" style={{ background: "#b93160" }}>
              <img
                className="img-fluid"
                src={require("../../assets/images/back2020.png")}
                alt=""
                srcset=""
              />
            </div>

          </div>
          <div className="col-xl-6 col-md-12 boxCenter">
            <div className="loginDiv2">
              <div className="loginPage pt-3">
                <div className="my-4">
                  <div className="loginLogo me-3 pt-1 pe-1">
                    <img
                      src={require("../../assets/images/Frame 162747.png")}
                      alt="Logo"
                      width="80px"
                    />
                  </div>
                </div>
                <div
                  style={{
                    color: "rgba(0, 0, 0, 0.50)",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    letterSpacing: "0.48px",
                  }}
                >
                  <p>Welcome back!!!</p>
                </div>
                <div className="mb-3">
                  <h3 className="fw-bold text-dark" style={{ fontSize: "56px", fontWeight: "600" }}>
                    Sign Up
                  </h3>
                </div>
                <div className="loginInput">
                  <Input
                    label="Email"
                    id="loginEmail"
                    type="email"
                    value={email}
                    style={{ background: "rgba(185, 49, 96, 0.11)" }}
                    errorMessage={error.email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError((prevError) => ({
                        ...prevError,
                        email: e.target.value ? "" : "Email is required!",
                      }));
                    }}
                  />
                  <Input
                    label="Password"
                    id="loginPassword"
                    type="password"
                    value={password}
                    errorMessage={error.password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setError((prevError) => ({
                        ...prevError,
                        password: e.target.value ? "" : "Password is required!",
                      }));
                    }}
                  />
                  <Input
                    label="New Password"
                    id="newPassword"
                    type="password"
                    value={newPassword}
                    errorMessage={error.newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setError((prevError) => ({
                        ...prevError,
                        newPassword: e.target.value ? "" : "New password is required!",
                      }));
                    }}
                  />
                  <Input
                    label="Purchase Code"
                    id="purchaseCode"
                    type="text"
                    value={code}
                    errorMessage={error.code}
                    onChange={(e) => {
                      setCode(e.target.value);
                      setError((prevError) => ({
                        ...prevError,
                        code: e.target.value ? "" : "Purchase code is required!",
                      }));
                    }}
                  />
                </div>
                <div className="loginButton boxCenter mt-5">
                  <Button
                    newClass="whiteFont"
                    btnColor="btnBlackPrime"
                    style={{
                      borderRadius: "20px",
                      width: "150px",
                      height: "46px",
                    }}
                    btnName="Sign Up"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { signupAdmin })(Registration);
