// BrandComponent.js
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchBrands, createBrand, updateBrand, deleteBrand } from "../../store/brand/brand.action";

const BrandComponent = (props) => {
  const dispatch = useDispatch();
  const { brands } = useSelector((state) => state.brand);

  const [newBrand, setNewBrand] = useState("");
  const [editBrand, setEditBrand] = useState({ id: "", name: "" });
  

  useEffect(() => {
    dispatch(fetchBrands());
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
    }, 1500); // Adjust the delay time as needed

    return () => clearTimeout(timer);
  }, []);


  const handleCreateBrand = () => {
    if (newBrand.trim()) {
      dispatch(createBrand(newBrand));
      setNewBrand("");
    }
  };

  const handleUpdateBrand = () => {
    if (editBrand.id && editBrand.name.trim()) {
      dispatch(updateBrand(editBrand.id, editBrand.name));
      setEditBrand({ id: "", name: "" });
    }
  };

  return (
    <div>
      <h1>Brand Management</h1>

      {/* Add New Brand */}
      <div>
        <input
          type="text"
          value={newBrand}
          onChange={(e) => setNewBrand(e.target.value)}
          placeholder="Enter new brand"
        />
        <button onClick={handleCreateBrand}>Add Brand</button>
      </div>

      {/* Edit Brand */}
      {editBrand.id && (
        <div>
          <input
            type="text"
            value={editBrand.name}
            onChange={(e) => setEditBrand({ ...editBrand, name: e.target.value })}
            placeholder="Update brand name"
          />
          <button onClick={handleUpdateBrand}>Update Brand</button>
          <button onClick={() => setEditBrand({ id: "", name: "" })}>Cancel</button>
        </div>
      )}

      {/* Brand List */}
      <ul>
        {brands.map((brand) => (
          <li key={brand._id}>
            {brand.name}
            <button onClick={() => setEditBrand({ id: brand._id, name: brand.name })}>
              Edit
            </button>
            <button onClick={() => dispatch(deleteBrand(brand._id))}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default connect(null, {
  fetchBrands, createBrand, updateBrand, deleteBrand
})(BrandComponent);
